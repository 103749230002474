import axios from "axios"
import { API_URL } from "src/env"

const useResumeApi = () => {}

export interface getCheckApplicantCredentialsInterface {
  vacancy_id: number
  email: string
  mobile: string
}
export const getCheckApplicantCredentials = async (data: getCheckApplicantCredentialsInterface) => {
  try {
    const response: any = await axios.get(API_URL + "/public/vacancies/check-applicant-credentials", {
      params: data
    })

    return response.data
  } catch (e: any) {
    console.error("error", e)
    throw e // Rethrow the error
  }
}

export default useResumeApi
