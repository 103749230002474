import { Dialog, Transition } from "@headlessui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { cx } from "class-variance-authority"
import { FC, Fragment } from "react"
import { FormProvider, useForm } from "react-hook-form"

import usePersonApi from "src/server/queryHooks/usePersonApi"
import { twMerge } from "tailwind-merge"

import { ResumeApplySchema } from "../../../types/schema/resumeApplyShcema"
import ResumeConfirmationStep from "./steps/ResumeConfirmationStep"
import IconButton from "../common/Button/IconButton"
import ResumeStepLoginOrFinishProfile from "./steps/ResumeStepLoginOrFinishProfile"
import ResumeStepGuestForm from "./steps/ResumeStepGuestForm"
import ResumeStepOtpForm from "./steps/ResumeStepOtpForm"
import ResumeSuccessStep from "./steps/ResumeSuccessStep"
import ModalWrapper from "src/views/components/Modal/ModalWrapper"

interface Props {
  vacancy_id: number | null
  resumeModalStep: number | null
  closeAndResetModal: () => any
}
const ResumeApplyContainer: FC<Props> = ({ vacancy_id, resumeModalStep, closeAndResetModal }) => {
  const methods = useForm({
    shouldUnregister: false,
    resolver: yupResolver(ResumeApplySchema())
  })
  const { naturalPersonQuery } = usePersonApi()

  const isOpen = resumeModalStep !== null && vacancy_id !== null
  // build
  const onClose = () => {
    methods.clearErrors()
    closeAndResetModal()
    methods.reset()
  }
  if (resumeModalStep === 5) {
    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="transition-opacity transition-transform ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition-opacity transition-transform ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={cx(
                    twMerge(
                      "overflow-x-none relative flex h-[170px]  max-h-[170px] w-full max-w-[560px] flex-col overflow-auto rounded-2xl bg-white px-6 pb-6 pt-6 shadow-xl"
                    )
                  )}
                >
                  <ResumeConfirmationStep vacancy_id={vacancy_id!} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    )
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      className={cx("max-h-[603px] max-w-[560px]", !resumeModalStep && "!h-0", true && "h-full")}
    >
      <div className="absolute right-4 top-4 opacity-70 hover:opacity-100">
        <IconButton src="/images/close.svg" onClick={onClose} />
      </div>
      {resumeModalStep === 1 && <ResumeStepLoginOrFinishProfile vacancy_id={vacancy_id!} />}
      <FormProvider {...methods}>
        {resumeModalStep === 2 && <ResumeStepGuestForm />}
        {resumeModalStep === 3 && <ResumeStepOtpForm />}
      </FormProvider>
      {resumeModalStep === 4 && <ResumeSuccessStep />}
      {resumeModalStep === 5 && <ResumeConfirmationStep vacancy_id={vacancy_id!} />}
      {/* Step 4 content */}
      {/* <ModalStep isOpen={step === 4} onClose={closeAndResetModal}>
            <div className='flex flex-col items-center gap-14'>
              <Image src='/images/mail_success.svg' width={360} height={290} alt='success mail' />

              <Typography type='h3'>რეზიუმე წარმატებით გაიგზავნა</Typography>

              <Button intent='primary' onClick={closeAndResetModal} text='დახურვა' className='w-full' />
            </div>
          </ModalStep> */}
    </ModalWrapper>
  )
}

export default ResumeApplyContainer
